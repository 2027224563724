import React, { useState, useEffect } from 'react'
import moment from 'moment'
// import {  } from '../../common'
import { ajax } from '../../ajax'
import { Button, Segment, Table, Header, Message, Icon, Divider, Statistic, Grid, Popup } from 'semantic-ui-react'
import { SmartSchedule } from '../SmartSchedule/SmartSchedule'
import { getEnglishText } from '../../dictionary'
import config from '../../config'

import './Savings.css'

function Savings({ isToday, units, startDateLocal, endDateLocal, rangeName }) {
  const [busy, setBusy] = useState(true)
  const [savingsData, setSavingsData] = useState(null)
  const [smartScheduleOpen, setSmartScheduleOpen] = useState(false)

  const [smartstorEnabled, setSmartstorEnabled] = useState(null)
  const [customerImportTarrif, setCustomerImportTarrif] = useState(null)
  const [customerExportTarrif, setCustomerExportTarrif] = useState(null)

  useEffect(() => {
    ;(async () => {
      setBusy(true)
      await fetchCustomerSettings()
      await fetchData()
      setBusy(false)
    })()
    return () => {}
  }, [units, startDateLocal, endDateLocal])

  ///////////////////////
  // CUSTOMER SETTINGS //
  ///////////////////////

  async function fetchCustomerSettings() {
    const { contract } = await ajax.rawRequest({ path: '/settings', queryParams: { hardwareId: units[0].hardwareId } })
    const { smartScheduleEnabled, exportTariffId, importTariffId } = contract
    setSmartstorEnabled(smartScheduleEnabled === 1)
    setCustomerImportTarrif(importTariffId || null)
    setCustomerExportTarrif(exportTariffId || null)
  }

  ////////////////////////
  // FETCH SAVINGS DATA //
  ////////////////////////

  async function fetchData() {
    const dateStart = startDateLocal.subtract(1, 'day').toISOString()
    const dateEnd = endDateLocal.subtract(1, 'day').subtract(1, 'second').toISOString()

    const _savings = []

    try {
      for (const u of units) {
        const { result, errorMessage, savings } = await ajax.rawRequest({ path: '/savings', queryParams: { hardwareId: u.hardwareId, dateStart, dateEnd } })
        if (result !== 'success') throw errorMessage
        _savings.push(...savings.map((s) => s.utcTotals))
        break  // stop at one, all should have the same totals
      }

      // very obviously pasted snippet!  It sums each of the numerical key values in each day of totals
      const sumValues = (arr) => {
        return arr.reduce((acc, curr) => {
          Object.keys(curr).forEach((key) => {
            if (typeof curr[key] === 'number') {
              acc[key] = (acc[key] || 0) + curr[key]
            } else if (!acc[key]) {
              acc[key] = curr[key]
            }
          })
          return acc
        }, {})
      }

      // set state
      setSavingsData(sumValues(_savings))
    } catch (error) {
      console.error(error)
    }
  }

  function getCalculation(name) {
    try {
      switch (name) {
        case 'savings-smartstor-on':
          return (savingsData.cost_nosolar_svr - savingsData.cost_battery).toFixed(2)
        case 'co2-saved-smartstor-on':
          return ((savingsData.demand_nosolar - (savingsData.demand_battery - savingsData.export_battery)) * config.savingsCaronIntensity / 1000).toFixed(2)
        default:
          return null
      }
    } catch (error) {
      return null
    }
  }

  ////////////
  // RENDER //
  ////////////

  return (
    <div className="Savings">
      {!busy && (
        <>
          {!customerImportTarrif ? (
            // customer does NOT have a tariff set
            <>
              <div className="no-tariff-set">
                No tariff set&nbsp;&nbsp;
                <Icon name="close" />
              </div>
              <Button primary content="TELL US YOUR TARIFF TO VIEW SAVINGS" onClick={() => setSmartScheduleOpen(true)} />
            </>
          ) : (
            // customer DOES has a tariff set
            <>
                <>
                  {isToday && <div className="yesterdays-estimates">Yesterday's estimates</div>}

                  <Divider horizontal>
                    <Header as="h4">
                      Savings 
                    </Header>
                  </Divider>

                  {rangeName == 'placeholder' ? (
                    <span>
                    Unable to calculate 'this year'
                  </span>
                  ) : (
                    <Grid columns={2} padded="horizontally" celled="internally">
                      <Grid.Row>
                        <Grid.Column>
                          { savingsData && (!Object.keys(savingsData).length || getCalculation('savings-smartstor-on') < 0 || getCalculation('co2-saved-smartstor-on') < 0) ? (
                            <div>
                              <Statistic size="tiny">
                                <Statistic.Value>
                                  <span>
                                    Cost Savings&nbsp;&nbsp;
                                    <Icon name="pound" className="green" />
                                  </span>
                                </Statistic.Value>
                              </Statistic>
                              <div className="no-data-available">
                              No data.
                              <br />
                              </div>
                            </div>
                          ) : (
                          <Popup trigger={
                            <Statistic size="tiny">
                              <Statistic.Value>
                                <span>
                                  Cost Savings&nbsp;&nbsp;
                                  <Icon name="pound" className="green" />
                                </span>£{getCalculation('savings-smartstor-on')}
                              </Statistic.Value>
                            </Statistic>
                          } content={
                            <small>Savings estimated by comparing the cost of your consumption using Ofgem published UK average price cap rates versus the cost of your consumption with your current tariff and battery and solar generation contribution. If you select 'today' you will see data for the previous day.</small>
                          } />
                          )}
                        </Grid.Column>
                        <Grid.Column>
                          { savingsData && (!Object.keys(savingsData).length || getCalculation('savings-smartstor-on') < 0 || getCalculation('co2-saved-smartstor-on') < 0) ? (
                            <div>
                              <Statistic size="tiny">
                                <Statistic.Value>
                                  <span>
                                    Carbon Savings &nbsp;&nbsp;
                                    <Icon name="leaf" className="green" />
                                  </span>
                                </Statistic.Value>
                              </Statistic>
                              <div className="no-data-available">
                              No data.
                              <br />
                              </div>
                            </div>
                          ) : (
                          <Popup trigger={
                            <Statistic size="tiny">
                              <Statistic.Value>
                                <span>
                                  Carbon Savings &nbsp;&nbsp;
                                  <Icon name="leaf" className="green" />
                                </span>
                                {getCalculation('co2-saved-smartstor-on')}
                              </Statistic.Value>
                              <Statistic.Label>kg CO<sub>2</sub></Statistic.Label>
                            </Statistic>
                          } content={
                            <small>Kg of CO<sub>2</sub> removed from the Grid as result of installing a battery and solar based on gas carbon intensity. Source: National Grid.</small>
                          } />
                          )}
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  )}

                  {/* <Divider horizontal>
                    <Header as="h4">
                      Carbon Savings&nbsp;&nbsp;
                      <Icon name="leaf" className="green" />
                    </Header>
                  </Divider> */}

                  {/* <Grid columns={1} padded="horizontally" celled="internally">
                    <Grid.Row>
                      <Grid.Column>
                        <Statistic size="tiny">
                          <Statistic.Value>
                            <span>
                              CO<sub>2</sub> saved
                            </span>
                            {getCalculation('co2-saved-smartstor-on')}
                          </Statistic.Value>
                          <Statistic.Label>kg</Statistic.Label>
                        </Statistic>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid> */}

                  {smartstorEnabled ? (
                    <>
                      <div className="smartstor-status enabled">
                        SMARTSTOR&trade; is currently enabled&nbsp;&nbsp;
                        <Icon name="check" />
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="smartstor-status disabled">
                        SMARTSTOR&trade; is currently disabled&nbsp;&nbsp;
                        <Icon name="close" />
                      </div>
                    </>
                  )}
                </>
            </>
          )}
        </>
      )}
      {/* </Segment> */}

      {smartScheduleOpen && (
        <SmartSchedule
          tariffPickerOnlyMode={true}
          units={units}
          onClose={() => setSmartScheduleOpen(false)}
          // onSaveSchedule={onSaveSchedule}
        />
      )}
    </div>
  )
}

export { Savings }
