import React, { useState } from 'react'
import { Modal, Button, Menu, Grid } from 'semantic-ui-react'
import { Link, useNavigate, Outlet } from 'react-router-dom'

import './Preferences.css'

function Preferences() {
  const navigate = useNavigate()

  return (
    <>
      <Modal
        id="Preferences"
        size="large"
        open
        centered={false}
        dimmer="blurring"
        closeOnDimmerClick={false}
        closeOnDocumentClick={false}
        onClose={(e) => {
          e.stopPropagation()
          navigate('/home')
        }}
      >
        <Outlet />
      </Modal>
    </>
  )
}

export { Preferences }
