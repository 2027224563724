import React, { useState, useEffect } from 'react'
import { ajax } from '../../ajax'
import { moment, parseModelCode } from '../../common'
import { Label, Icon } from 'semantic-ui-react'

import './VOCSensor.css'

const UPDATE_INTERVAL_MS = 5000
const FRESH_DATA_THRESHOLD_SECONDS = 60 * 2 // 2 minutes
let timeoutHandle = null

function VOCSensor({ units }) {
  const [vocSensorConnected, setVocSensorConnected] = useState(null)

  useEffect(() => {
    return () => clearTimeout(timeoutHandle)
  }, [])

  useEffect(() => {
    const { vocSensorFitted } = parseModelCode(units[0].model)
    if (vocSensorFitted === true) {
      fetchData()
    } else {
      setVocSensorConnected(null)
      clearTimeout(timeoutHandle)
    }
  }, [units])

  async function fetchData() {
    const response = await ajax.fetchLastMeasurement({ hardwareId: units[0].hardwareId, measurementName: 'vocSensorConnected' })
    const lastDataTime = response?.lastData?.vocSensorConnected[0]
    const dataIsFresh = moment.utc(lastDataTime).isAfter(moment.utc().subtract(FRESH_DATA_THRESHOLD_SECONDS, 'seconds'))

    if (!dataIsFresh) return setVocSensorConnected(false) // TODO: if not a p3 then set to null

    const vocValue = response?.lastData?.vocSensorConnected[1]
    if (vocValue === undefined || vocValue === null) setVocSensorConnected(false) // TODO: if not a p3 then set to null
    else setVocSensorConnected(true)

    clearTimeout(timeoutHandle)
    timeoutHandle = setTimeout(fetchData, UPDATE_INTERVAL_MS)
  }

  return (
    <>
      <div className="VOCSensor">
        {vocSensorConnected === true && (
          <Label color="green" basic size="small">
            VOC Sensor Connected&nbsp;&nbsp;&nbsp;
            <Icon name="check" fitted />
          </Label>
        )}
        {vocSensorConnected === false && (
          <Label basic size="small">
            VOC Sensor Disconnected&nbsp;&nbsp;&nbsp;
            <Icon name="exclamation" fitted />
          </Label>
        )}
      </div>
    </>
  )
}

export { VOCSensor }
