import React, { useState } from 'react'
import { Modal, Button, Header, Checkbox, AccordionTitle, AccordionContent, Accordion, Icon, Divider } from 'semantic-ui-react'
import { Link, useNavigate } from 'react-router-dom'

import './GatewaySettings.css'

function GatewaySettings() {
  const navigate = useNavigate()

  const [busy, setBusy] = useState(false)

  const [showMore, setShowMore] = useState(null)

  return (
    <>
      <Modal.Header>Gateway Settings</Modal.Header>
      <Modal.Content className="GatewaySettings" scrolling>
        <Header>Automated Force Charge</Header>
        <p>We scan for Met Office severe weather alerts containing a power outage warning.</p>
        <p>
          Powervault will override manual and SMARTSTOR<sup>&trade;</sup> schedules to Force Charge your battery to 100% and give you backup power.
        </p>

        <div style={{ padding: '1em' }}>
          <Checkbox toggle label="Opt in to auto-charging" checked />
        </div>

        <Accordion className="indented">
          <AccordionTitle active={showMore === 0} index={0} onClick={() => setShowMore(0)}>
            <Icon name="dropdown" />
            Learn more ...
          </AccordionTitle>
          <AccordionContent active={showMore === 0}>
            <p>Force Charge starts as soon as the alert is received and returns to Force Normal afterwards.</p>
            <p>Charging is set to 6 hours or when the battery reaches 100%.</p>
            <p>Be ready for a power cut with a full battery, without having to do anything yourself.</p>
            <p>We will send you an SMS to confirm charging and the opportunity to stop the charge event.</p>
          </AccordionContent>
        </Accordion>

        <br />
        <p>Please tell us below if you would like to receive SMS alerts.&nbsp;&nbsp;(If you do not subscribe, we will not contact you.)</p>
        <div style={{ padding: '1em' }}>
          <Checkbox toggle label="Force Charge events" checked />
          <br />
          <br />
          <Checkbox toggle label="Advice to manually Force Charge" />
        </div>

        <Accordion className="indented">
          <AccordionTitle active={showMore === 1} index={1} onClick={() => setShowMore(1)}>
            <Icon name="dropdown" />
            Learn more ...
          </AccordionTitle>
          <AccordionContent active={showMore === 1}>
            <p>Using Met Office data provides accurate and reliable weather forecasts with power cut predictions.</p>
            <p>If you have opted in to severe weather event Automated Force Charging we will send you an SMS when charging starts.</p>
            <p>If you have opted out, we will send you an SMS to advise you to manually Force Charge your battery.</p>
          </AccordionContent>
        </Accordion>

        <Divider hidden />
        <Divider />
        <Divider hidden />

        <Header>Managing your energy during a power cut</Header>

        <p>
          Once you have a fully-charged battery, you will need to manage your power reserve by unplugging or turning off energy-intensive devices and
          appliances.
        </p>
        <div className="appliances">
          <Header as="h4" color="green">
            Standard Appliances
            <Header.Subheader>Use normally</Header.Subheader>
          </Header>
          <div>
            <Icon size="big" circular name="coffee" label="test" />
            <small>Coffee Machine</small>
          </div>
          <div>
            <Icon size="big" circular name="mobile alternate" />
            <small>Phone</small>
          </div>
          <div>
            <Icon size="big" circular name="lightbulb outline" />
            <small>Lights</small>
          </div>
          <div>
            <Icon size="big" circular name="television" />
            <small>Television</small>
          </div>
          <div>
            <Icon size="big" circular name="laptop" />
            <small>Laptop</small>
          </div>
          <div>
            <Icon size="big" circular name="wifi" />
            <small>Internet Router</small>
          </div>
          <div>
            <Icon size="big" circular name="video camera" />
            <small>CCTV</small>
          </div>

          <Header as="h4" color="red">
            Energy-Intensive Appliances
            <Header.Subheader>Consider not using these</Header.Subheader>
          </Header>
          <div>
            <Icon size="big" circular name="car" />
            <small>Car Charging</small>
          </div>
          <div>
            <Icon size="big" circular name="shower" />
            <small>Electric Shower</small>
          </div>
          <div>
            <Icon size="big" circular name="thermometer" />
            <small>Electric Heating</small>
          </div>
        </div>

        <p>
          Your <strong>Live View</strong> shows an estimation of how long the battery energy could last, calculated by analysing the current home energy
          consumption rate, so you can make an informed decision about what you don't need whilst off-grid.
        </p>

        <p>
          You've charged your battery and unplugged any energy-intensive devices but you may want to actively manage the level of energy stored in your
          Powervault battery.
        </p>
        <p>
          The countdown timer on the <strong>Live View</strong> shows an estimation of how long the battery energy could last so you can turn off non-essential
          devices.
        </p>
        <p>The good news is the Powervault battery will continue to charge off-grid if there is good enough solar generation.</p>
        <p>
          In the event of a power cut, stay in touch with your utility company for information on how long the power cut will last, especially for planned
          maintenance scenarios.
        </p>
        <Divider hidden />
      </Modal.Content>
      <Modal.Actions>
        <Button disabled={busy} loading={busy} secondary as={Link} to="/home">
          Close
        </Button>
      </Modal.Actions>
    </>
  )
}

export { GatewaySettings }
